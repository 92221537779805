<template>
  <section class="book-form-section">
  <v-container>
    
    <!-- Form for adding/editing a book -->
    <v-card class="form-card">
      <v-card-title>Ajouter un Livre</v-card-title>
      <v-card-text>
    <v-form ref="form" v-model="valid" @submit.prevent="submitForm" >
      <v-row>
        <v-col>
      <v-text-field
        v-model="book.titre"
        label="Titre"
        :rules="[v => !!v || 'Titre est requis']"
        required
        outlined
      ></v-text-field>
    </v-col>
  <v-col>
      <v-text-field
        v-model="book.nbrPage"
        label="Nombre des Pages"
        type="number"
        :rules="[v => !!v || 'Nombre des pages est requis', v => (v && v > 0) || 'Number of pages must be greater than 0']"
        required
        outlined
      ></v-text-field>
    </v-col>
    </v-row>
    <v-row>
      <v-col>
      <VueEditor
        v-model="book.description"
        auto-grow
        outlined
        label="Description"
      
        placeholder="Description"
      >
      </VueEditor>
    </v-col>
    </v-row>
  <v-row>
    <v-col>
      <v-select
        v-model="book.levelId"
        :items="levels"
        item-value="id"
        item-text="name"
        label="Niveau"
        :rules="[v => !!v || 'Niveau est requis']"
        required
        outlined
      ></v-select>
    </v-col>
  <v-col>
      <v-select
        v-model="book.categoryId"
        :items="categories"
        item-value="id"
        item-text="name"
        label="Categorie"
        :rules="[v => !!v || 'Categorie est requis']"
        required
        outlined
      ></v-select>
    </v-col>
  <v-col>
      <v-select
        v-model="book.authorId"
        :items="authors"
        item-value="id"
        item-text="name"
        label="Auteur"
        :rules="[v => !!v || 'Auteur est requis']"
        required
        outlined
      ></v-select>
    </v-col>
    </v-row>
      <v-row v-if="book.titre">
        <v-col cols="12" sm="6" md="6">
          <!-- VueUploadMultipleImage component for handling images -->
          <vue-upload-multiple-image
            v-model="images"
            :data-images="images"
            name="images"
            drag-text="Please add an image!"
            browse-text="Browse image"
            primary-text="Primary image"
            mark-is-primary-text="Set default image"
            :min-image="1"
            :max-image="3"
            :showEdit="false"
            :multiple="true" 
            :loading="load"
            popup-text="Description default image"
            drop-text="Drag and drop"
            @upload-success="uploadImageSuccess" 
            @before-remove="beforeRemove"
          ></vue-upload-multiple-image>
        </v-col>
      </v-row>
      <v-row v-if="uploadInProgress">
  <v-col cols="12">
    <v-progress-linear
      indeterminate
      color="primary"
      height="5"
    ></v-progress-linear>
  </v-col>
</v-row>
      <v-btn type="submit" color="info" :loading="loading">
  {{ buttonLabel }}
</v-btn>
    </v-form>
      </v-card-text>
    </v-card>
    <v-divider class="my-4"></v-divider>
    <!-- Table for displaying books -->
    <v-card >
        <v-card-title>Liste des Livres</v-card-title>
      <v-text-field
        style="padding:15px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Recherche"
        single-line
        hide-details
        clearable
        :search="search"
      ></v-text-field>
    <div>

    <v-data-table
      :headers="headers"
      :items="books"
      item-key="id"
      :search="search"
      :loading="loading"
      :no-data-text="noDataText"
      :items-per-page="itemsPerPage"
      :page.sync="pageNumber"
      :server-items-length="totalBooks"
      @update:page="fetchBooks"
      hide-default-footer

    >
    <template v-slot:item.description="{ item }">
      <span v-html="SplitDescription(item.description)"></span>
    </template>
      <template v-slot:item.listFileUrl="{ item }">
        <v-img v-if="item.listFileUrl && item.listFileUrl.length > 0" :src="item.listFileUrl[0]" width="100px" height="100px" style="border-radius: 10px;margin:10px;box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);"></v-img>
      </template>
      <template v-slot:item.levelId="{ item }">
        {{ getLevelName(item.levelId) }}
      </template>
      <template v-slot:item.categoryId="{ item }">
        {{ getCategoryName(item.categoryId) }}
      </template>
      <template v-slot:item.authorId="{ item }">
        {{ getAuthorName(item.authorId) }}
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon class="edit-icon" @click="editBook(item)">mdi-pencil</v-icon>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-icon class="delete-icon" @click="confirmDeleteBook(item.id)">mdi-delete</v-icon>
      </template>
    </v-data-table>
<!-- Pagination Component -->
<v-pagination
      v-model="pageNumber"
      :length="totalPages"
      @input="fetchBooks"
    ></v-pagination>  </div>

    </v-card>

     <!-- Confirmation Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" max-width="530">
        <v-card>
          <v-card-title class="text-h6">
            Etes vous sur de vouloir supprimer le livre ?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1 error"
              text
             @click="dialog = false"
            >
              Annuler
            </v-btn>

            <v-btn color="info darken-1 " text @click="deleteBook">
              Confirmer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
 
  </v-container>
</section>
</template>

<script>
import VueUploadMultipleImage from 'vue-upload-multiple-image';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import axios from 'axios';
import Constant from "@/utils/constants";

import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

export default {
  components: {
    VueUploadMultipleImage,
    VueEditor,
  },
  computed: {
    buttonLabel() {
      return this.book.id ? 'Modifier' : 'Ajouter';
    }
  },
  data() {
    return {
      uploadInProgress: false, // Track upload progress
      isImgUploaded : false,
      books: [],
      book: {
        id: null,
        titre: '',
        description: '',
        nbrPage: null,
        price: '',
        quantity: null,
        levelId: null,
        categoryId: null,
        authorId: null,
        listFileUrl: [], // Initialize as an empty array for multiple images
        listFileId: [], // Initialize as an empty array for corresponding IDs
        createdBy: localStorage.getItem('ownerId')
      },
      levels: [],
      categories: [],
      authors: [],
      images: [],
      load: false,
      loading: false,
      valid: false,
      search: '',
      noDataText: 'No categories found',
      headers: [
        { text: 'Image', value: 'listFileUrl' },  
        { text: 'Titre', value: 'titre' },
        { text: 'Description', value: 'description' },
        { text: 'Nombre des Pages', value: 'nbrPage' },
        { text: 'Niveau', value: 'levelId' },
        { text: 'Categorie', value: 'categoryId' },
        { text: 'Auteur', value: 'authorId' },
        { text: 'Modifier', value: 'edit', sortable: false },
        { text: 'Supprimer', value: 'delete', sortable: false },
      ],
      pageNumber: 1, 
      totalPages: 1, 
      itemsPerPage: 10, 
      totalBooks: 0, 
      dialog: false,
      deleteBookId: null,
    };
  },
  created() {
    this.fetchBooks();
    this.fetchLevels();
    this.fetchCategories();
    this.fetchAuthors();
  },
  methods: {
    async fetchBooks() {
      //this.loading = true;
      try {
        const response = await axios.get(`${Constant.QORANI}library/book/getall/page/${this.pageNumber}`);
        this.books = response.data.bookResponseList;
        this.totalPages = response.data.totalPageNumber;
        this.totalBooks = response.data.totalBooks; // Adjust if your API returns total books count
      } catch (error) {
        console.error('Error fetching books:', error);
      } 
    },
    
    fetchLevels() {
      axios.get(Constant.QORANI +'library/level/getall')
        .then(response => {
          this.levels = response.data;
        })
        .catch(error => {
          console.error('Error fetching levels:', error);
        });
    },
    fetchCategories() {
      axios.get(Constant.QORANI +'library/category/getall')
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    },
    fetchAuthors() {
      axios.get(Constant.QORANI +'library/author/getall')
        .then(response => {
          this.authors = response.data;
        })
        .catch(error => {
          console.error('Error fetching authors:', error);
        });
    },
    getLevelName(levelId) {
      const level = this.levels.find(level => level.id === levelId);
      return level ? level.name : 'Unknown';
    },
    getCategoryName(categoryId) {
      const category = this.categories.find(category => category.id === categoryId); 
      return category ? category.name : 'Unknown';
    },
    getAuthorName(authorId) {
      const author = this.authors.find(author => author.id === authorId);
      return author ? author.name : 'Unknown';
    },
    
    async submitForm() {
  // Trigger validation
  this.$refs.form.validate();

  // Check if form is valid
  if (!this.valid ) {
    console.log("Form validation failed.");
    return;
  }

  try {
    this.loading = true;

    // Use this.book instead of book
    if (this.book.id) {
      // Edit existing book
      const response = await fetch(Constant.QORANI + `library/book/edit/${this.book.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.book),
      });
      if (response.ok) {
        console.log('Livre mis à jour avec succès !');
      } else {
        console.error('Erreur lors de la mise à jour du livre :', response.statusText);
      }
    } else {
      // Add new book
      const response = await fetch(Constant.QORANI + "library/book/add", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.book),
      });
      const data = await response.json();
      if (response.ok) {
        
        this.book.id = data.id; // assuming API returns ID
        console.log('Livre ajouté avec succès !');

      } else {
        console.error('Erreur lors de l\'ajout du livre :', response.statusText);
      }
    }
    this.resetForm();
    // Reset form fields
    this.$refs.form.reset();
    this.fetchBooks(); // Refresh book list

  } catch (error) {
    console.error('Erreur lors de l\'envoi du formulaire :', error);
  } finally {
    this.loading = false;
  }
},
    
    editBook(book) {
      this.book.id = book.id;
      this.book.titre = book.titre;
      this.book.description = book.description;
      this.book.nbrPage = book.nbrPage;
      this.book.price = book.price;
      this.book.quantity = book.quantity;
      this.book.levelId = book.levelId;
      this.book.categoryId = book.categoryId;
      this.book.authorId = book.authorId;
      this.book.listFileId = book.listFileId;
      this.book.listFileUrl = book.listFileUrl;
      // this.images.push({
      //     name: "",
      //     path: book.listFileUrl,
      //     highlight: 1,
      //     default: 1,
      //   });
      if (book.listFileUrl !== null && Array.isArray(book.listFileUrl)) {
    this.images = book.listFileUrl.map(url => ({
      name: "", 
      path: url,
      highlight: 1,
      default: 1,
    }));
  } else {
    this.images = []; 
  }
      // Défilement automatique vers le formulaire
  this.$nextTick(() => {
    const formElement = this.$refs.form.$el; // Récupérer l'élément DOM du formulaire
    formElement.scrollIntoView({ behavior: 'smooth' }); // Faire défiler en douceur
  });
    },
    
    resetForm() {
      this.book = {
      id: null,
      titre: '',
      description: '',
      nbrPage: null,
      price: '',
      quantity: null,
      levelId: null,
      categoryId: null,
      authorId: null,
      listFileUrl: [], 
      listFileId: [], 
      createdBy: localStorage.getItem('ownerId')
    };
  },
    confirmDeleteBook(bookId) {
      this.dialog = true;
      this.deleteBookId = bookId;
    },
    
    async deleteBook() {
      try {
        await fetch(Constant.QORANI + `library/book/delete/${this.deleteBookId}`, {
          method: 'GET',
        });
        this.fetchBooks(); // Refresh book list
      } catch (error) {
        console.error(`Erreur lors de la suppression du livre ${this.deleteBookId}:`, error);
      } finally {
        this.dialog = false;
      }
    },
    SplitDescription(description) {
      if (!description) return '';
    // var y = x.split(' ').slice(0,2).join('+');
    const word = description.split(' ').slice(0,5).join(' ');
    return word + (description.split(' ').length > 5 ? '...' : '');
    //return word + (word.length > 5 ? '...' : '');
  },
  
    handleImagesUpdated(images) {
      const uploadedImage = images[0];
      console.log("Image téléchargée :", uploadedImage);
    },
    
    async uploadImageSuccess(formData, index, fileList) {
      try {
        this.uploadInProgress = true; // Show the upload progress indicator
        this.isImgUploaded = false;
        const uploadedImage = fileList[index];
        const imageName = uploadedImage.name;
        const ext = imageName.slice(imageName.lastIndexOf(".") + 1);
        const contentType = `image/${ext}`;
        const currentDateTime = new Date();
        const formattedDateTime = `${currentDateTime.getFullYear()}_${(currentDateTime.getMonth() + 1)
          .toString()
          .padStart(2, "0")}_${currentDateTime
          .getDate()
          .toString()
          .padStart(2, "0")}_${currentDateTime
          .getHours()
          .toString()
          .padStart(2, "0")}_${currentDateTime
          .getMinutes()
          .toString()
          .padStart(2, "0")}_${currentDateTime
          .getSeconds()
          .toString()
          .padStart(2, "0")}`;
        const newImageName = `${imageName}_${formattedDateTime}`;
        
        const file = this.convertImagePathToFile(uploadedImage);
        const storageRef = firebase.app().storage().ref(`books/${newImageName}`);
        
        // Perform the upload task
        const uploadTask = storageRef.put(file, { contentType });
        
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Handle upload progress if needed
            const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.uploadPercentage = progress;
          },
          (error) => {
            console.error("Error uploading image:", error);
            alert(`Error uploading image ${error.message}`);
            
          },
          async () => {
            // Upload completed successfully
            const url = await storageRef.getDownloadURL();
            
            // Update book object with uploaded image URL and ID
            try {
              const response = await fetch(Constant.QORANI + "files/upload", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ fileName: imageName, fileUrl: url }),
              });

              if (response.ok) {
                const responseData = await response.json();
                this.book.listFileId.push(responseData.id); // Assuming it's an array of IDs
                this.book.listFileUrl.push(url); // Assuming it's an array of URLs
                this.isImgUploaded = true;
                this.uploadInProgress = false;
              } else {
                console.error(
                  "Error uploading image. Server response status:",
                  response.status
                );
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        );
      } catch (error) {
        console.error("Error uploading image:", error);
        alert(`Error uploading image ${error.message}`);
      }
    },
    
    beforeRemove(index, done, fileList) {
  console.log('Avant suppression :', index, fileList);
  
  const confirmation = confirm("Voulez-vous vraiment supprimer cette image ?");
  
  if (confirmation) {
    console.log('Suppression confirmée pour l\'index :', index);
    this.images = [];
    this.book.listFileId = [];
    this.book.listFileUrl = [];
    this.$emit('input', this.images);
    done(); 
  } 
},
    
    convertImagePathToFile(element) {
      const byteString = atob(element.path.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      
      const blob = new Blob([ia], { type: "image/jpeg" });
      return new File([blob], element.name);
    },
  },
};
</script>
<style scoped >
/* Base styles */
.book-form-section {
  padding: 24px;
  background-color: #f4f7f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Card styling */
.form-card, .v-card {
  margin-bottom: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff; /* Clean white background */
}

/* Text field styling */
.v-text-field {
  margin-bottom: 16px; /* Spacing between fields */
  width: 100%; /* Full width for inputs */
}

/* Quill editor styling */
.q-editor {
  margin-bottom: 40px; /* Space below editor */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

/* Button styling */
.v-btn {
  border-radius: 6px;

}

/* Divider styling */
.v-divider {
  margin: 24px 0; /* Increased spacing for dividers */
}

/* Data table styling */
.v-data-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.v-data-table thead th {
  background-color: #f4f5f7; /* Light gray for headers */
  color: #333;
  font-weight: 500;
  border-bottom: 2px solid #e0e0e0;
}

/* Icon styling */
.v-icon {
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

/* Search field styling */
.v-text-field[v-model="search"] {
  max-width: 350px; /* Slightly wider search field */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .book-form-section {
    padding: 16px; /* Reduced padding for smaller screens */
  }

  .v-row {
    flex-direction: column; /* Stack elements vertically */
  }

  .v-col {
    width: 100%; /* Full width for each column on small screens */
  }

  .v-data-table {
    font-size: 14px; /* Smaller font size for tables */
  }

  .v-text-field, .v-select {
    margin-bottom: 12px; /* Reduced margin for mobile */
  }

  .v-btn {
    width: 100%; /* Full width buttons */
  }
}

@media (min-width: 769px) {
  .v-col {
    max-width: 45%; /* Allow two items per row on larger screens */
    margin: 0 2.5%; /* Horizontal margin for spacing */
  }

  .v-data-table {
    font-size: 16px; /* Default font size for desktop */
  }
}
</style>

